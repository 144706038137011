

@media screen and (min-width: 10px) {
    .message-container{
        width: 70%;
        height: 90%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        border: 3px solid #FFF;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
    }
    .message-container div {
        width: 230px;
    }
    #messageIMG {
        display: none;
    }
    .comment {
        font-size: 15px;
        font-weight: 300;
        line-height: normal;
        letter-spacing: 0.6px;
        padding: 0px 20px;
    }
    .name {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: .8px;
    }
}

@media screen and (min-width: 501px) {
    .message-container{
        width: 75%;
        flex-direction: row;
        justify-content: space-around;
    }
    #messageIMG {
        display: inline;
        width: 50px;
    }
    .comment {
        width: 450px;
    }
}

@media screen and (min-width: 1025px) {
    .comment {
        padding: 10px 30px;
        letter-spacing: .8px;
    }
    .name {
        font-size: 17px;
    }
}
