.reason-container{
    padding: 50px 40px 150px;
    text-align: center;
    background-color: white;
    box-shadow: 0px 0px 15px 3px rgb(0, 0, 0);
    transition: all 0.3s ease 0s;
    cursor: pointer;    
    height: 200px;
    border-bottom: 5px solid transparent;
}

.reason-container:hover{
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
    transform: translateY(-5px);
    border-bottom: 5px solid #30B2DC;
}

.logo{
    width: 45px;
    height: 45px;
    margin: 10px;
}

.reason-title{
    font-weight: 600;
    color: #282828;
}

.descrip{
    margin: 0 auto;
    font-size: 16px;
    line-height: 25px;
    color: #747474;
}


@media screen and (max-width: 500px) {
    .logo{
        width: 45px;
        height: 45px;
        margin: 5px;
    }
    .descrip{
        max-width: 100%;
    }
    .reason-container{
        padding: 50px 40px;
        height: auto;
    }
}


@media screen and (min-width: 1025px) {
    .logo{
        width: 45px;
        height: 45px;
        margin: 15px;
    }
    .descrip{
        max-width: 250px;
    }
}