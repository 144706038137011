.background {
    background-color: #2f2f2f;
    display: flex;
    flex-direction: column;
}

.Services .title{
    color: white;
}

.services-container{
    display: flex;
    justify-content: space-between;
    gap: 15px;
}


@media screen and (max-width: 669.9px) {
    .background{
        margin: 150px 0px 75px;
        padding-top: 70px;
    }
    .Services {
        margin: 0px 20px;
    }
    .services-container{
        flex-direction: column;
        gap: 75px;
        margin: 75px 0px;
    }
}

@media screen and (min-width: 670px) and (max-width: 869.9px) {
    .background{
        margin: 200px 0px 75px;
        padding-top: 50px;
    }
    .Services {
        margin: 0px 30px;
    }
    .services-container{
        flex-direction: column;
        gap: 75px;
        margin: 75px 0px;
    }
}

@media screen and (min-width: 870px) and (max-width: 1024px) {
    .background{
        margin-bottom: 75px;
        justify-content: center;
    }
    .Services {
        margin: 0px 30px;
    }
    .services-container{
        gap: 40px;
        margin: 75px 0px;
    }
}

@media screen and (min-width: 1025px) {
    .background{
        margin-bottom: 75px;
        justify-content: center;
    }
    .Services {
        margin: 0px auto;
        max-width: 1024px;
    }
    .services-container{
        gap: 40px;
        margin: 80px 0px;
    }
}