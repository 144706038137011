.checkbox {
	opacity: 0;
	position: absolute;
    top: 50%;
}

.label {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px;
	position: relative;
	height: 16px;
	width: 40px;
	transform: scale(1.5);
}
.ball div{
    height: 20px;
	width: 20px;
	background-color: rgb(255, 255, 255);
}
.label .ball{
	background-color: transparent;
	position: absolute;
	top: 2px;
	left: 2px;
	height: 22px;
	width: 22px;
	transform: translateX(0px);
	transition: transform 0.2s linear;
    overflow: hidden;
}

.checkbox:checked + .label .ball {
	transform: translateX(24px);
}

.label_text{
    font-size: 8px;
    font-weight: 1000;
    color: black;
}
.container_toggle{
    position: absolute;
    top: 50;
}