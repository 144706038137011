@media screen and (min-width: 10px) {
    .Comments{
        height: 250px;
        margin: 50px 15px 90px;
    }
    .comments-container{
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
    }
    .indicators-container {
        margin-top: 30px;
        display: flex;
        justify-content: center;
    }
    .indicator {
        width: 10px;
        height: 10px;
        background-color: #ddd;
        margin: 0 5px;
        cursor: pointer;
    }
    .active {
        background-color: #2f2f2f;
    }
    .comments-button {
        color: #2f2f2f;
        border: none;
        background: none;
        cursor: pointer;
        font-size: large;
        font-family: 'Lilita One', sans-serif;
        font-size: 30px;
        font-style: normal;
        font-weight: bolder;
        line-height: normal;
    }
}

@media screen and (min-width: 501px) {
    .Comments{
        height: 200px;
    }
    .comments-container{
        width: 90%;
        margin: 0 auto;
    }
}

@media screen and (min-width: 769px){
    .Comments{
        height: 200px;
        margin: 100px 25px 150px;
    }
    .comments-container{
        width: 100%;
    }
    .indicators-container {
        margin-top: 40px;
    }
}

@media screen and (min-width: 1025px) {
    .comments-container{
        height: 200px;
        width: 100%;
        margin: 30px auto 30px;
        max-width: 1024px;
    }
}