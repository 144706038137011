.burger{
    margin-top: 20px;
    width: 30px;
    height: 21px;
    position: absolute;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    right: 1.5rem;
}

.bar{
    height: 3px;
    width: 100%;
    background-color: #2f2f2f;
}


@media screen and (max-width: 768px) {
    .burger {
        display: flex;

    }
}