#contact {
  margin: 40px 30px;
  height: 600px;
  color: #2f2f2f;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}   

.title {
  font-size: 30px;
  letter-spacing: 3px;
  font-weight: 600;
}

#form-container{
  width: 100%;
  height: 100%;
  margin-top: 40px;
  background-image: url(../../assets/contact.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}

#form-contact {
  height: 387.5px;
  background-color: #FFF;
  box-shadow: 0px 4px 25px 0px rgba(24, 24, 24, 0.25);
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 25px;
}

.contact-inputs{
  border: 1px solid #2f2f2f;
  padding: 10px;
  font-weight: 400;
  font-family: 'Montserrat';
}

.contact-inputs::placeholder {
  color: rgb(0, 0, 25);
}

.contact-inputs:focus {
  color: white;
  background-color: #2f2f2f;
  transition: 1s;
}

.contact-inputs:focus::placeholder {
  color: white;
  transition: 1s;
}

#form-contact #text {
  padding-bottom: 60px;
}

#form-contact .btn-form {
  position: absolute;
  width: 150px;
  height: 45px;
  top: 5;
  right: 25px;
  left: 5;
  bottom: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputs {
  height: 250px;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
}

.btn-form{
  font-family: 'Montserrat';
  color: white;
  background-color: #2f2f2f;
  font-size: 16px;  
  cursor: pointer;
}

.btn-form:hover{
  color: #2f2f2f;
  border: 1px solid #2f2f2f;
  background-color: white;
  transition: 1s;
}

@media screen and (max-width: 500px) {
  #form-container{
    background-image: none;
  }
  #form-contact .btn-form {
    bottom: 30px;
  }
}

@media screen and (min-width: 501px) and (max-width: 768px) {
  #form-container{
    background-image: none;
  }
  #form-contact .btn-form {
    bottom: 30px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  #contact {
    margin: 50px 30px;
    height: 750px;
  }
  #form-container{
    margin-top: 40px;
  }
  #form-contact {
    position: absolute;
    left: 42.5%;
    top: 20%;
    width: 50%;
    height: 55%;
  }
}

@media screen and (min-width: 1025px) {
  #contact {
    margin: 50px auto;
    height: 850px;
    max-width: 1024px;
  }
  #form-container{
    margin-top: 50px;
  }
  #form-contact {
    position: absolute;
    left: 45%;
    top: 20%;
    width: 50%;
    height: 45%;
  }
}