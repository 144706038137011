#footerBackground{
    margin: 150px 0px 0px;
    height: 1000px;
    background-color: #2f2f2f;
}

.footer {
    margin: 0 auto;
    padding: 50px 30px 0px;
    color: rgb(228, 226, 226);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer ul {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
}

.footer li {
    margin: 15px 0px;
}

.footerGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.footer h2 {
    margin-bottom: 30px;
    font-size: 20px;
}

.footer p {
    font-size: 16px;
    line-height: 25px;
}

#footerTechbay {
    width: 100%;
}

#footerLogos {
    margin-top: 35px;
    width: 75px;
    display: flex;
    justify-content: space-between;
}

.footerIMG {
    width: 25px;
}


@media screen and (max-width: 574.9px) {
    #footerBackground{
        margin: 100px 0px 0px;
        height: 1425px;
    }
    .footerGrid {
        display: grid;
        grid-template-columns: 1fr;
    }
    #footerLogos {
        margin-top: 50px;
    }
}

@media screen and (min-width: 575px) and (max-width: 869.9px) {
    #footerBackground{
        margin: 100px 0px 0px;
    }
}

@media screen and (min-width: 870px) and (max-width: 1024.9px) {
    #footerLogos {
        margin-top: 50px;
    }
}

@media screen and (min-width: 1025px) {
    #footerBackground{
        height: 525px;
    }
    .footer {
        padding: 50px 0px 0px;
        max-width: 1024px;
    }
    .footer ul {
        flex-direction: row;
        text-align: start;
    }
    .footer li {
        margin: 0px;
    }
    .footerGrid {
        display: inline;
        grid-template-columns: 1fr 1fr;
    }
    .footer p {
        line-height: 45px;
    }
    #footerTechbay {
        width: 300px;
    }
    #footerLogos {
        margin-top: 40px;
    }
}